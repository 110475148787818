tailwind.config = {
    theme: {
        screens: {
            sm: '640px',
            md: '768px',
            lg: '1024px',
            '3xl': '1180px',
            xl: '1280px',
            '2xl': '1280px'
        },
        container: {
            screens: {
                sm: '640px',
                md: '768px',
                lg: '1024px',
                '3xl': '1180px',
                xl: '1280px',
                '2xl': '1280px'
            }
        },
        extend: {
            screens: {
                '3xl': '1180px',
            },
            colors: {
                primary: '#D71F70',
                'primary-hover': '#4A148C',
                header: '#F9F9F9',
                'header-text': '#111827'
            },
            borderRadius: {
                app: '8px'
            },
            fontFamily: {
                primary: ['Ubuntu', 'sans-serif'],
                secondary: ['Ubuntu', 'sans-serif'],
                tertiary: ['Ubuntu', 'sans-serif']
            }
        }
    },
    corePlugins: {
        divideStyle: true
    }
};